import React, {memo} from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import PageWrapper from '../containers/PageWrapper';
import ContactForm from '../modules/ContactForm';

import Section from '../components/Section';
import UnderlineHeading from '../components/UnderlineHeading';
import SectionDescription from '../components/SectionDescription';

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      workProcess: file(relativePath: { eq: "aboutUs/work_process.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      team: file(relativePath: { eq: "aboutUs/team.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      location: file(relativePath: { eq: "aboutUs/location.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      at: file(relativePath: { eq: "aboutUs/at.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      phone: file(relativePath: { eq: "aboutUs/phone.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <PageWrapper
      title="About Us"
      description="CoderyLabs was founded by group of IT professionals with one common goal - to
            provide highly specialized service focusing on delivering web solutions based on
            Liferay platform. Currently we have consultants, architects and developers on
            board with vast experience and customer- oriented approach."
      urlPath="about-us"
      keywords={['coderylabs, about us', 'our team', 'how we work']}
    >
      <Section>
          <UnderlineHeading><Trans>About Us</Trans></UnderlineHeading>
        <SectionDescription>
            <Trans>About Us desc</Trans>
        </SectionDescription>
      </Section>

      <Section theme="dark">
        <UnderlineHeading><Trans>Our team</Trans></UnderlineHeading>
        <SectionDescription>
          <Trans>Our team desc</Trans>
        </SectionDescription>
        <Team>
          <TeamItem>
            {/* <Img fluid={data.team.childImageSharp.fluid} alt="CoderyLabs.com Jarosław Marciniak" /> */}
            <H3>Jarosław Marciniak</H3>
            <H4>Managing Director</H4>
            <SectionDescription style={{textAlign: 'left'}}>
              <Trans>JM desc</Trans>
            </SectionDescription>
          </TeamItem>
          <TeamItem>
            {/*
                        <Img
                            fluid={data.team.childImageSharp.fluid}
                            alt="CoderyLabs.com Wojciech Tutro"
                        />
                        */}
            <H3>Wojciech Tutro</H3>
            <H4>CTO</H4>
            <SectionDescription style={{textAlign: 'left'}}>
              <Trans>WT desc</Trans>
            </SectionDescription>
          </TeamItem>
          <TeamItem>
            {/*
                        <Img
                            fluid={data.team.childImageSharp.fluid}
                            alt="CoderyLabs.com Bartłomiej Knabel"
                        />
                        */}
            <H3>Bartłomiej Knabel</H3>
            <H4>Lead Architect</H4>
            <SectionDescription style={{textAlign: 'left'}}>
                <Trans>BK desc</Trans>
            </SectionDescription>
          </TeamItem>
        </Team>
      </Section>

      <Section id="how-we-work">
        <UnderlineHeading><Trans>How we work</Trans></UnderlineHeading>
        <SectionDescription>
            <Trans>How we work desc</Trans>
        </SectionDescription>
        <Img
          fluid={data.workProcess.childImageSharp.fluid}
          style={{
            width: '100%',
            maxWidth: '960px',
            height: '100%',
            margin: '0 auto',
            marginTop: '2rem',
          }}
          imgStyle={{
            objectFit: 'cover',
          }}
          alt="Work Process Image"
        />
      </Section>

      <Section theme="dark">
        <UnderlineHeading>Company contact</UnderlineHeading>
        <ContactDataContainer>
          <ContactCards>
            <ContactCardsItem>
              <Img fixed={data.location.childImageSharp.fixed} alt="Location icon"/>
              <address>
                CoderyLabs Sp. z o.o. S.K. <br/>
                Kraków, Poland 30-554 <br/>
                Zamknięta 10 st. <br/>
                NIP: 6793158637 <br/>
                KRS: 0000701444
              </address>
            </ContactCardsItem>
            <ContactCardsItem>
              <Img fixed={data.at.childImageSharp.fixed} alt="Location at"/>
              <address>contact@coderylabs.com</address>
            </ContactCardsItem>
            <ContactCardsItem>
              <Img fixed={data.phone.childImageSharp.fixed} alt="Location phone"/>
              <address>
                +48 668-594-571 <br/>
              </address>
            </ContactCardsItem>
          </ContactCards>

          <MapContainer>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.468403349611!2d19.959738315716585!3d50.04005617942085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b51f3918a21%3A0x6d143b29b83e3496!2sZamkni%C4%99ta%2010%2C%2030-554%20Krak%C3%B3w!5e0!3m2!1spl!2spl!4v1591044284807!5m2!1spl!2spl"
              frameBorder="0"
              style={{border: 0, width: '100%', minHeight: '450px'}}
              aria-hidden="false"
              title="map"
            />
          </MapContainer>
        </ContactDataContainer>
      </Section>

      <Section theme="dark">
        <UnderlineHeading><Trans>Contact Us</Trans></UnderlineHeading>
        <SectionDescription>
          <Trans>Contact Us Hint</Trans>
        </SectionDescription>
        <ContactForm/>
      </Section>
    </PageWrapper>
  );
};

const Team = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  flex-direction: column;

  @media screen and (min-width: 680px) {
    flex-direction: row;
  }
`;

const TeamItem = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 2rem;

  @media screen and (min-width: 680px) {
    padding: 0.5rem;
    box-sizing: border-box;
  }
`;

const H3 = styled.h2`
  margin: 0.5rem 0;
  font-size: 1.5rem;
`;

const H4 = styled.h4`
  margin: 0.5rem 0;
  font-size: 1.1rem;
  color: #39b54a;
`;

const ContactDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 940px) {
    flex-direction: row;
  }
`;

const ContactCards = styled.div`
  width: 100%;

  address {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.5;
  }

  @media screen and (min-width: 940px) {
    width: 50%;
  }
`;

const ContactCardsItem = styled.div`
  display: flex;
  width: 99%;
  padding: 1.5rem;
  background: white;
  box-shadow: 3px 3px 6px #00000029;

  box-sizing: border-box;
  margin-bottom: 1rem;
  border-radius: 10px;

  @media screen and (min-width: 940px) {
    width: 90%;
  }
`;

const MapContainer = styled.div`
  width: 100%;

  @media screen and (min-width: 940px) {
    width: 50%;
  }
`;

export default memo(AboutUs);
